import i18next from 'i18next';
import { EN_GB, FR_CA } from '@clearscore/config.i18n';

import { PAGE_NAMESPACE } from './lib/constants';
import { multilingualFeatureActive } from './helpers';
import copyGb from './copy/pages/login/en-gb/copy.json';
import copyFr from './copy/pages/login/fr-ca/copy.fr-ca.json';

export { default as Component } from './login';

i18next.addResourceBundle(EN_GB, PAGE_NAMESPACE, copyGb);

if (multilingualFeatureActive()) {
    i18next.addResourceBundle(FR_CA, PAGE_NAMESPACE, copyFr);
}
