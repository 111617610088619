import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import useLanguage from '@clearscore/hooks.use-language';
import Text from '@clearscore/rainbow.text';
import Icon from '@clearscore/rainbow.icons.warning-inline';
import isNumber from '@clearscore/validation.is-number';

import MultipleSingleDigits from './components/multiple-single-digits';
import Resend from './components/resend';
import Submit from './components/submit';
import styles from './index.module.css';
import lang from './lib/lang';

const NUMBER_OF_INPUT_FIELDS = 6;
const FIELD_NAME = 'otpCandidate';

const getErrorText = (boxIndexes, language) => {
    const { length } = boxIndexes;
    if (length === 1) return `${language.boxSingular} ${boxIndexes[0]}`;

    if (length === 2) return `${language.boxesPlural} ${boxIndexes[0]} ${language.and} ${boxIndexes[1]}`;

    return boxIndexes.reduce((accumulator, currentValue, index) => {
        if (length - 1 === index) {
            const accumulatorWithoutLastComma = accumulator.slice(0, -1);
            return `${language.boxesPlural}${accumulatorWithoutLastComma} ${language.and} ${currentValue}`;
        }

        return `${accumulator} ${currentValue},`;
    }, '');
};

const getNonNumericIndexes = ({ individualInputs = [] } = {}) =>
    individualInputs.reduce((accumulator, currentValue, index) => {
        if (currentValue === '') return accumulator;

        const isNotNumber = isNumber(currentValue) === false;

        if (isNotNumber) {
            return [...accumulator, index + 1];
        }

        return accumulator;
    }, []);

const validateFormValue = (values) => {
    const currentValue = values && values.inputValue;
    const isFormMissingValues = currentValue && currentValue.length !== NUMBER_OF_INPUT_FIELDS;

    if (isFormMissingValues || isNumber(currentValue) === false) {
        return { [FIELD_NAME]: 'error' };
    }

    return {};
};

const FormInner = ({
    generateOtp,
    generateOtpStatus,
    verifyOtpStatus,
    otpCoolDownSeconds,
    isResendLastAttempt,
    resetFormWhen,
    handleSubmit,
    shouldShowResend,
    form,
}) => {
    const language = useLanguage(lang);
    const [fieldKey, setFieldKey] = useState(0);
    const clearOtpField = () => setFieldKey(fieldKey + 1);

    const { submitSucceeded, submitFailed, dirtySinceLastSubmit, ...formState } = form.getState();
    const nonNumericIndexes = getNonNumericIndexes(formState.values[FIELD_NAME]);
    const isFormInvalid = formState.invalid;

    const isFormDirty = formState.modified[FIELD_NAME];
    const wasFormSubmittedBefore = submitSucceeded || submitFailed;
    const isDirtyAfterError = wasFormSubmittedBefore ? dirtySinceLastSubmit && verifyOtpStatus.hasFailed : true;

    const resetForm = () => {
        form.reset();
        clearOtpField();
    };

    useEffect(() => {
        if (resetFormWhen) resetForm();
    }, [resetFormWhen]);

    const handleGenerateOtp = () => {
        resetForm();
        generateOtp();
    };

    return (
        <form name="OTPVerificationForm" onSubmit={handleSubmit}>
            <Field
                key={fieldKey}
                name={FIELD_NAME}
                component={MultipleSingleDigits}
                numberOfFields={NUMBER_OF_INPUT_FIELDS}
                isDisabled={verifyOtpStatus.isPending || verifyOtpStatus.isComplete}
                isDirtyAfterError={isDirtyAfterError}
            />

            <div className={styles.formErrorWrapper}>
                {nonNumericIndexes && nonNumericIndexes.length >= 1 ? (
                    <React.Fragment>
                        <Icon height={14} width={14} />
                        <Text.Caption>
                            {`${language.pleaseEnter} ${getErrorText(nonNumericIndexes, language)}`}
                        </Text.Caption>
                    </React.Fragment>
                ) : null}
            </div>

            <div className={styles.buttonsWrapper}>
                <Submit
                    language={language}
                    isSubmitDisabled={!isFormDirty || isFormInvalid || !isDirtyAfterError}
                    verifyOtpStatus={verifyOtpStatus}
                />
                {shouldShowResend && (
                    <Resend
                        language={language}
                        generateOtp={handleGenerateOtp}
                        generateOtpStatus={generateOtpStatus}
                        otpCoolDownSeconds={otpCoolDownSeconds}
                    />
                )}
            </div>

            {isResendLastAttempt ? (
                <Text.Body1 dataId="otp-form-last-attempt">{language.lastAttempt}</Text.Body1>
            ) : null}
        </form>
    );
};

FormInner.propTypes = {
    isResendLastAttempt: PropTypes.bool.isRequired,
    generateOtp: PropTypes.func.isRequired,
    generateOtpStatus: PropTypes.any.isRequired,
    verifyOtpStatus: PropTypes.any.isRequired,
    otpCoolDownSeconds: PropTypes.any,
    resetFormWhen: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    form: PropTypes.shape({
        getState: PropTypes.func,
        reset: PropTypes.func,
    }).isRequired,
    shouldShowResend: PropTypes.bool,
};

FormInner.defaultProps = {
    resetFormWhen: false,
    otpCoolDownSeconds: null,
    shouldShowResend: true,
};

const Component = ({ verifyOtp, ...rest }) => (
    <Form
        data-qa="form-otp"
        validate={(formData) => validateFormValue(formData[FIELD_NAME])}
        onSubmit={(formData) => verifyOtp(formData[FIELD_NAME].inputValue)}
        render={({ handleSubmit, form }) => <FormInner {...rest} handleSubmit={handleSubmit} form={form} />}
    />
);

Component.propTypes = {
    verifyOtp: PropTypes.func.isRequired,
};

export default Component;
