import React from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormOtp from '@clearscore-group/ui.form-otp';
import Text from '@clearscore/rainbow.text';
import { useTranslation } from 'react-i18next';
import { GB } from '@clearscore/config.i18n';

import { actions, selectors } from '../../redux';
import marketConfig from './lib/market-config';
import styles from './screen-verify.module.css';
import { PAGE_NAMESPACE } from '../../lib/constants';

interface IScreenVerify {
    location: {
        state: {
            requestMeta: Record<string, unknown>;
            mobileNumber?: string;
            twoFactorType: string;
            coolDownSeconds: number;
        };
    };
}

interface IStatus {
    isInitial: boolean;
    isPending: boolean;
    isComplete: boolean;
    hasFailed: boolean;
    statusCode?: number;
}

interface IState {
    status?: 'initial' | 'pending' | 'success';
    data?: Record<string, unknown>;
    statusCode?: number;
    request?: Record<string, unknown>;
    meta: Record<string, unknown>;
}

const TWO_FACTOR_MOBILE_NUMBER_TYPE = 'sm';

const ScreenVerify = ({ location: { state } }: IScreenVerify): React.ReactElement => {
    const { t } = useTranslation(PAGE_NAMESPACE);
    const { requestMeta, coolDownSeconds, twoFactorType, ...restState } = state || {};
    const hasLocationState = state && !!Object.keys(state).length;
    const market = requestMeta?.market || GB;
    const { linkCantGetCode } = marketConfig[market];
    const dispatch = useDispatch();
    const resendStatus: IStatus = useSelector(selectors.resendTwoFactorAuth.getStatus);
    const resendState: IState = useSelector(selectors.resendTwoFactorAuth.getState);
    const verifyStatus: IStatus = useSelector(selectors.verifyTwoFactorAuth.getStatus);
    const verifyState: IState = useSelector(selectors.verifyTwoFactorAuth.getState);
    const handleResend = (): void => dispatch(actions.resendTwoFactorAuth(restState, requestMeta));
    const handleVerify = (code: string): void =>
        dispatch(actions.verifyTwoFactorAuth({ ...restState, code }, requestMeta));
    const isMobileType = twoFactorType === TWO_FACTOR_MOBILE_NUMBER_TYPE;

    if (!hasLocationState) {
        return <Redirect to="/login" />;
    }
    return (
        <React.Fragment>
            <div className={styles.title}>
                <Text.H1>{t('screenVerify.title')}</Text.H1>
            </div>

            <div className={styles.description}>
                <Text.Body1 dataId="login-screen-verify-description">
                    {isMobileType
                        ? t('screenVerify.description', { mobileNumber: state.mobileNumber })
                        : t('screenVerify.appDescription')}
                </Text.Body1>
            </div>

            <FormOtp
                verifyOtp={handleVerify}
                verifyOtpStatus={verifyStatus}
                generateOtp={handleResend}
                generateOtpStatus={resendStatus}
                otpCoolDownSeconds={
                    isMobileType
                        ? coolDownSeconds || (resendState.data && resendState.data.cool_down_seconds) || 0
                        : null
                }
                isResendLastAttempt={!!verifyState.data && verifyState.data.attempts_remaining === 1}
                shouldShowResend={isMobileType}
            />

            <div className={styles.footNotes}>
                <Text.Link1 href={linkCantGetCode} dataId="login-screen-verify-help-link">
                    {t('screenVerify.cantGetCode')}
                </Text.Link1>
            </div>
        </React.Fragment>
    );
};

export default ScreenVerify;
