import { useCallback } from 'react';
import useWebappTracking from '@clearscore/hooks.use-webapp-tracking';

const getWindowProps = () => ({
    source: window.location.pathname,
});

export default () => {
    const track = useWebappTracking();

    const trackBannerViewed = useCallback(
        () =>
            track({
                name: 'fe_mobile_web_smart_banner_viewed',
                // Removed other variants, so variant will always be BUTTON now
                props: { variant: 'BUTTON', ...getWindowProps() },
            }),
        [track],
    );

    const trackBannerClicked = useCallback(
        () =>
            track({
                name: 'fe_mobile_web_smart_banner_clicked',
                // Removed other variants, so variant will always be BUTTON now
                props: { variant: 'BUTTON', ...getWindowProps() },
            }),
        [track],
    );

    return { trackBannerViewed, trackBannerClicked };
};
