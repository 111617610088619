import getEnv from '@clearscore/helpers.envs';
import { types as sessionTypes } from '@clearscore/redux.session';
import { constants } from '@clearscore/redux.alerts';
import Text from '@clearscore/rainbow.text';
import i18next from 'i18next';

import { types } from '../redux';
import { PAGE_NAMESPACE } from './constants';

const alertMessageWithComponents = (message, props) => ({
    message,
    alertType: constants.WARNING,
    Components: [{ Component: Text.Link, props: { ...props, key: 'link' } }],
});

const alertWithMessage = (message, alertType = constants.WARNING) => ({
    message,
    alertType,
    ...(alertType === constants.ALERT_INFO && { dismissTimeout: 5000 }),
});

const alertWithCtaLink = ({ message, href, ctaText, isNewTab = false }) => ({
    message,
    alertType: constants.WARNING,
    href,
    ctaText,
    isNewTab,
});

const alertWithSentCodeMessage = (locale, mobileNumber) => {
    const t = i18next.getFixedT(locale, PAGE_NAMESPACE);
    return alertWithMessage(t('successSentCode', { mobileNumber }), constants.ALERT_INFO);
};

const alertWithAppVerifyMessage = (locale) => {
    const t = i18next.getFixedT(locale, PAGE_NAMESPACE);
    return alertWithMessage(t('appCodeCopy'), constants.ALERT_INFO);
};

export default {
    [sessionTypes.LOGIN_OAUTH_SUBMIT_ERROR]: ({ payload: { data, status }, locale }) => {
        const t = i18next.getFixedT(locale, PAGE_NAMESPACE);
        switch (status) {
            case 503:
                return alertWithMessage(t('maintenanceMode'));
            case 400:
                if (data.final_attempt) {
                    return alertWithCtaLink({
                        message: t('finalAttempt'),
                        href: '/reset',
                        ctaText: t('wrongPasswordCta'),
                    });
                }

                if (data.reason === 'temp_locked') {
                    return alertWithCtaLink({
                        message: t('error400TempLocked'),
                        href: '/reset',
                        ctaText: t('error400TempLockedCta'),
                    });
                }

                if (data.reason === 'locked') {
                    return alertMessageWithComponents(t('error400Locked'), {
                        isNewTab: true,
                        href: `${getEnv('INTERNATIONAL_WEBSITE')}/askaquestion`,
                    });
                }

                if (data.reason === 'dup_locked') {
                    return alertMessageWithComponents(t('error400Duplocked'), {
                        isNewTab: true,
                        href: `${getEnv('INTERNATIONAL_WEBSITE')}/askaquestion`,
                    });
                }

                if (data.reason === 'blocked') {
                    return alertMessageWithComponents(t('blockedUserError'), {
                        isNewTab: true,
                        href: `${getEnv('INTERNATIONAL_WEBSITE')}/askaquestion`,
                    });
                }

                return alertWithCtaLink({
                    message: t('wrongPassword'),
                    href: '/reset',
                    ctaText: t('wrongPasswordCta'),
                });
            case 429:
                return alertWithMessage(t('error429'));

            default:
                return alertWithMessage(t('defaultError', { status }));
        }
    },

    [types.verifyTwoFactorAuth.error]: ({ locale }) => {
        const t = i18next.getFixedT(locale, PAGE_NAMESPACE);
        return alertWithMessage(t('errorVerifyIncorrect'));
    },
    [types.resendTwoFactorAuth.error]: ({ locale }) => {
        const t = i18next.getFixedT(locale, PAGE_NAMESPACE);
        return alertWithMessage(t('errorSentCode'));
    },
    [types.resendTwoFactorAuth.success]: ({ requestMeta, locale }) =>
        alertWithSentCodeMessage(locale, requestMeta.mobileNumber),
    [types.showSentCodeAlert.pending]: ({ payload, locale }) => alertWithSentCodeMessage(locale, payload),
    [types.showTwoFactorAppAlert.pending]: ({ locale }) => alertWithAppVerifyMessage(locale),
};
