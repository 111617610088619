/* eslint-disable camelcase */
import { push } from 'connected-react-router';
import { ofType } from 'redux-observable';
import { of, concat } from 'rxjs';
import { flatMap, filter } from 'rxjs/operators';
import { actions as marketActions } from '@clearscore/redux.market';
import { types as sessionTypes, actions as sessionActions } from '@clearscore/redux.session';
import getEnv from '@clearscore/helpers.envs';
import { actions as alertsActions } from '@clearscore/redux.alerts';
import i18n from 'i18next';

import { types, actions } from '../../redux';
import { RESPONSE_REQUIRES_2FA } from '../constants';

/**
 * Helper function for determining if market is set to maintenance mode.
 *
 *  MAINTENANCE_MODE_LOGIN env var is a string allowing to set multiple markets by
 * definining comma separated string (e.g. "gb,za,in").
 *
 * @param {object} action$ - action object
 * @param {object} action$.payload - payload object
 * @param {string} action$.payload.marketId - marketID
 */
const isMarketInMaintenanceMode = ({ requestMeta }) => {
    const marketId = requestMeta?.market_id ?? '';

    const maintenanceMode = getEnv('MAINTENANCE_MODE_LOGIN');
    const isValidMaintenanceMode = typeof maintenanceMode === 'string' && maintenanceMode.length > 0;
    const isValidMarketId = typeof marketId === 'string' && marketId.length > 0;

    if (!isValidMaintenanceMode || !isValidMarketId || maintenanceMode === 'VOID') return false;

    return maintenanceMode.toUpperCase().includes(marketId.toUpperCase());
};

/**
 * Checks if a market dashboard (not signup route) is in maintenance mode
 */
const maintenanceModeEpic = (action$) =>
    action$.pipe(
        ofType(sessionTypes.LOGIN_OAUTH_AUTHORISE_SUCCESS),
        filter(isMarketInMaintenanceMode),
        flatMap(() => of(push('/maintenance'))),
    );

/**
 *
 * @param {object} action$
 */
const oauthTokenTransfer = (action$, state$) =>
    action$.pipe(
        ofType(sessionTypes.LOGIN_OAUTH_SUBMIT_SUCCESS, types.verifyTwoFactorAuth.success),
        flatMap(
            ({
                payload: {
                    code,
                    market_id,
                    response_type,
                    '2fa_state': twoFactorAuthState,
                    '2fa_hint': mobileNumber,
                    '2fa_type': twoFactorType,
                    cool_down_seconds: coolDownSeconds,
                    sso_token,
                },
                requestData: { client_id, remember },
                requestMeta: { session_id },
            }) => {
                const market = market_id.toLowerCase();

                if (response_type === RESPONSE_REQUIRES_2FA) {
                    const locationQuery = state$.value.router.location.search;

                    return concat(
                        of(
                            push(`/login/verify${locationQuery}`, {
                                twoFactorAuthState,
                                mobileNumber,
                                coolDownSeconds,
                                twoFactorType,
                                client_id,
                                remember,
                                requestMeta: {
                                    market,
                                    session_id,
                                },
                            }),
                        ),
                        of(
                            twoFactorType === 'sm'
                                ? actions.showSentCodeAlert(mobileNumber)
                                : actions.showTwoFactorAppAlert(),
                        ),
                    );
                }

                return concat(
                    of(alertsActions.dismissAlert()),
                    of(
                        sessionActions.authorise(
                            {
                                code,
                                client_id,
                                session_id,
                            },
                            {
                                market,
                                market_id,
                                remember,
                                sso_token,
                            },
                        ),
                    ),
                );
            },
        ),
    );

/**
 * Epic called when oauth authorise is successful, here market id is setup and
 * an action to signify login has been successful is achieved
 * @param {object} action$ redux action
 */
const oauthTokenSuccess = (action$) =>
    action$.pipe(
        ofType(sessionTypes.LOGIN_OAUTH_AUTHORISE_SUCCESS),
        flatMap(
            // eslint-disable-next-line camelcase
            ({ requestMeta: { market } }) => {
                // todo: get locale from user-preference or dropdown on login
                const [language] = i18n.language.split('-');
                const validLocale = i18n.languages.find((lang) => lang === `${language}-${market}`);
                if (validLocale) {
                    i18n.changeLanguage(validLocale);
                } else {
                    i18n.changeLanguage(`en-${market}`);
                }
                return concat(of(alertsActions.dismissAlert()), of(marketActions.setActiveMarket(market)));
            },
        ),
    );

export default {
    maintenanceModeEpic,
    oauthTokenTransfer,
    oauthTokenSuccess,
};
