import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useInterval from '@clearscore/hooks.use-interval';
import Button from '@clearscore/rainbow.button';

const SUBMIT_COOL_DOWN_SECONDS = 5;

const Submit = ({ verifyOtpStatus, isSubmitDisabled, language }) => {
    const [remainingDisabledTime, setRemainingDisabledTime] = useState(0);

    useInterval(() => {
        if (remainingDisabledTime > 0) {
            setRemainingDisabledTime(remainingDisabledTime - 1);
        }
    }, 1000);

    useEffect(() => {
        if (verifyOtpStatus.hasFailed) {
            setRemainingDisabledTime(SUBMIT_COOL_DOWN_SECONDS);
        }
    }, [verifyOtpStatus]);

    return (
        <Button
            htmlType="submit"
            dataId="retry-otp-verify"
            type={Button.types.PRIMARY}
            size={Button.sizes.LARGE}
            isLoading={verifyOtpStatus.isPending || verifyOtpStatus.isComplete}
            isDisabled={remainingDisabledTime > 0 || isSubmitDisabled}
            isResponsive
        >
            {language.next}
        </Button>
    );
};

Submit.propTypes = {
    isSubmitDisabled: PropTypes.bool.isRequired,
    language: PropTypes.shape({
        next: PropTypes.string.isRequired,
    }).isRequired,
    verifyOtpStatus: PropTypes.shape({
        isPending: PropTypes.bool.isRequired,
        isComplete: PropTypes.bool.isRequired,
        hasFailed: PropTypes.bool.isRequired,
    }).isRequired,
};

export default Submit;
