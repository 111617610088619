import { GB, ZA, AU, CA } from '@clearscore/config.i18n';

export default {
    [ZA]: {
        linkCantGetCode: 'https://help.clearscore.co.za/hc/en-us/articles/360010196839-I-can-t-get-my-code',
    },
    [GB]: {
        linkCantGetCode: 'https://help.clearscore.com/hc/en-us/articles/360010196719-I-can-t-get-my-code',
    },
    [AU]: {
        linkCantGetCode: 'https://help.clearscore.com.au/hc/en-us/articles/360015490699-I-can-t-get-my-code-2FA',
    },
    [CA]: {
        linkCantGetCode: 'https://help.clearscore.ca/hc/en-us/articles/8403001596562-I-can-t-get-my-code-2FA',
    },
};
