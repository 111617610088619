const KEY_LOGOUT_THEME = 'CS_LOGOUT_THEME';

export const setLogoutTheme = (theme) => window.localStorage?.setItem(KEY_LOGOUT_THEME, theme);

export const clearLogoutTheme = () => window.localStorage?.removeItem(KEY_LOGOUT_THEME);

export const getLogoutTheme = ({ clear = false } = {}) => {
    const theme = window.localStorage?.getItem(KEY_LOGOUT_THEME);

    if (clear) clearLogoutTheme();

    return theme;
};
