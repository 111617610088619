import React from 'react';
import WebappAppDownloadBanner from '@shared/webapp-app-download-banner';
import Spacer from '@clearscore/rainbow.spacer';
import { useTranslation } from 'react-i18next';

import styles from './app-download-banner.module.css';
import { PAGE_NAMESPACE } from '../../lib/constants';

// These do not need to live in market-config at the moment as app store links and tracking are global
const PLAY_STORE_LINK = 'https://clearscore.app.link/GAFiEG2pTbb';
const IOS_STORE_LINK = 'https://clearscore.app.link/FxpHNO5pTbb';

const AppDownloadBanner = (): React.ReactElement => {
    const { t } = useTranslation(PAGE_NAMESPACE);

    return (
        <div className={styles.bannerWrapper}>
            <Spacer all={{ bottom: Spacer.spacings.BIG }} phablet={{ bottom: Spacer.spacings.SUPER }}>
                <WebappAppDownloadBanner
                    hasBorderBottom
                    text={{ title: t('appDownloadBanner.title') }}
                    playStoreLink={PLAY_STORE_LINK}
                    iosStoreLink={IOS_STORE_LINK}
                />
            </Spacer>
        </div>
    );
};

export default AppDownloadBanner;
