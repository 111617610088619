import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { isAndroidBrowser, isIOSBrowser } from '@clearscore/helpers.device';
import useLanguage from '@clearscore/hooks.use-language';
import useMarketConfig from '@clearscore/hooks.use-market-config';
import ClearscoreIcon from '@clearscore/rainbow.icons.clearscore-logo-symbol';
import Text from '@clearscore/rainbow.text';
import Spacer from '@clearscore/rainbow.spacer';
import Button from '@clearscore/rainbow.button';
import useAsyncClickout from '@clearscore/hooks.use-async-clickout';

import marketConfig from './lib/market-config';
import lang from './lib/lang';
import styles from './styles.module.css';

const ButtonCta = ({ onClick, ctaText }) => (
    <Button
        isNarrow
        isFlat
        type={Button.types.SECONDARY}
        theme={Button.themes.LIGHT}
        size={Button.sizes.SMALL}
        onClick={onClick}
        dataId="app-download-banner-cta"
    >
        {ctaText}
    </Button>
);

ButtonCta.propTypes = {
    onClick: PropTypes.func.isRequired,
    ctaText: PropTypes.string.isRequired,
};

const ContextualAppDownloadBanner = ({
    text,
    playStoreLink,
    iosStoreLink,
    hasBorderTop,
    hasBorderBottom,
    onBannerClick,
    onBannerView,
    setOpenModal,
    showOnDesktop,
}) => {
    const { isEnabled } = useMarketConfig(marketConfig);
    const { title, description, ctaText } = text;
    const { defaultCtaText } = useLanguage(lang);

    const isAndroid = isAndroidBrowser();
    const isIOS = isIOSBrowser();

    const appStoreLink = isAndroid ? playStoreLink : iosStoreLink;
    const shouldShow = isEnabled && (isAndroid || isIOS || showOnDesktop);

    const [shouldOpenAppStoreLink, setShouldOpenAppStoreLink] = useState(false);

    useAsyncClickout({ externalUrl: appStoreLink, trigger: shouldOpenAppStoreLink });

    useEffect(() => {
        if (shouldOpenAppStoreLink) {
            setShouldOpenAppStoreLink(false);
        }
    }, [shouldOpenAppStoreLink]);

    useEffect(() => {
        if (shouldShow) {
            onBannerView();
        }
    }, [shouldShow]);

    if (!shouldShow) {
        return null;
    }
    return (
        <section
            className={cx(styles.wrapper, {
                [styles.hasBorderTop]: hasBorderTop,
                [styles.hasBorderBottom]: hasBorderBottom,
            })}
            role="banner"
            data-qa="contextual-app-download-banner"
        >
            <div className={styles.innerWrapper}>
                <div className={styles.leftContent}>
                    <Spacer all={{ horizontal: Spacer.spacings.MEDIUM }}>
                        <div className={styles.iconOuterWrapper}>
                            <div className={styles.iconInnerWrapper}>
                                <ClearscoreIcon />
                            </div>
                        </div>
                    </Spacer>
                    <Spacer all={{ vertical: Spacer.spacings.SMALL }}>
                        <Text type={Text.types.MINI} weight={Text.weights.MEDIUM}>
                            {title}
                        </Text>
                        {description ? (
                            <Text type={Text.types.TINY} weight={Text.weights.THIN}>
                                {description}
                            </Text>
                        ) : null}
                    </Spacer>
                </div>
                <div className={styles.rightContent}>
                    <Spacer all={{ horizontal: Spacer.spacings.MEDIUM }}>
                        <ButtonCta
                            onClick={() => {
                                onBannerClick();
                                showOnDesktop ? setOpenModal(true) : setShouldOpenAppStoreLink(true);
                            }}
                            ctaText={ctaText || defaultCtaText}
                            isAndroid={isAndroid}
                        />
                    </Spacer>
                </div>
            </div>
        </section>
    );
};

ContextualAppDownloadBanner.propTypes = {
    text: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        ctaText: PropTypes.string,
    }),
    playStoreLink: PropTypes.string.isRequired,
    iosStoreLink: PropTypes.string.isRequired,
    hasBorderTop: PropTypes.bool,
    hasBorderBottom: PropTypes.bool,
    onBannerClick: PropTypes.func.isRequired,
    onBannerView: PropTypes.func.isRequired,
    setOpenModal: PropTypes.func,
    showOnDesktop: PropTypes.bool,
};

ContextualAppDownloadBanner.defaultProps = {
    text: {
        description: null,
        ctaText: null,
    },
    hasBorderTop: false,
    hasBorderBottom: false,
    setOpenModal: null,
    showOnDesktop: false,
};

export default ContextualAppDownloadBanner;
