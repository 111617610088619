/* eslint-disable jsx-a11y/tabindex-no-positive, camelcase */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { Trans, useTranslation } from 'react-i18next';
import type { History } from 'history';
import getUid from '@clearscore/helpers.uid';
import { isAndroidBrowser } from '@clearscore/helpers.device';
import { setCookie, getCookie } from '@clearscore/helpers.cookies';
import { actions as sessionActions } from '@clearscore/redux.session';
import { isRequiredValidation } from '@clearscore/validation.is-required';
import { isEmailValidation } from '@clearscore/validation.is-email';
import normalizeEmail from '@clearscore/normalize.email';
import Text from '@clearscore/rainbow.text';
import Button from '@clearscore/rainbow.button';
import TextInput from '@clearscore/rainbow.text-input';
import Password from '@clearscore/rainbow.password';
import Checkbox from '@clearscore/rainbow.checkbox';
import Label from '@clearscore/rainbow.label';
import Dropdown from '@clearscore/rainbow.dropdown';
import FormLayout from '@clearscore/rainbow.form-layout';
import Notification, { NotificationController } from '@clearscore/rainbow.notification';

import { COOKIE_ANONYMOUS_ID, PAGE_NAMESPACE } from '../../lib/constants';
import { multilingualFeatureActive } from '../../helpers';
import style from './styles/index.module.css';
import marketConfig from './lib/market-config';

export interface ILocationState {
    registeredEmailValue?: string;
    hasSubmittedLoginForm?: boolean;
}

type LocationHistory = History<ILocationState>;

const getEmailValue = (history: LocationHistory): string | undefined => {
    // param comes from failed login attempt (js-modules.library/scopes/epic/website-signup/src/index.js)
    const params = new URLSearchParams(history.location.search);
    const isAlreadyRegisteredPath = history.location.pathname.includes('/already-registered');
    const emailParam = params.get('email');

    if (isAlreadyRegisteredPath && emailParam) {
        return emailParam;
    }

    // state comes from failed signup attempt
    return history.location.state?.registeredEmailValue;
};

const clearEmailFromLocation = (history: LocationHistory): URL => {
    const {
        location: { pathname, search, hash },
    } = history;

    const url = new URL(`${pathname}${search}${hash}`, window.location.origin);
    url.searchParams.delete('email');

    return url;
};

const clearBrowserHistory = (history: LocationHistory): void => {
    const { pathname, search } = clearEmailFromLocation(history);
    history.replace({
        pathname,
        search,
    });
};

const getAnonymousId = (): string => {
    let anonymousId = getCookie(COOKIE_ANONYMOUS_ID);

    if (!anonymousId) {
        anonymousId = getUid();
        setCookie(COOKIE_ANONYMOUS_ID, anonymousId);
    }

    return anonymousId;
};

type THandleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => void;

interface IUseHandleLanguages {
    showLanguageSelector: boolean;
    languageOptions: {
        text: string;
        value: string;
    }[];
    currentLanguage: string;
    handleLanguageChange: THandleLanguageChange;
}

const useHandleLanguages = (): IUseHandleLanguages => {
    const { i18n } = useTranslation(PAGE_NAMESPACE);
    const showLanguageSelector = multilingualFeatureActive();
    const languageOptions = marketConfig.languages;
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

    const handleLanguageChange: THandleLanguageChange = async (event) => {
        const language = event.target.value;

        await i18n.changeLanguage(language);

        setCurrentLanguage(language);
    };

    return { showLanguageSelector, languageOptions, currentLanguage, handleLanguageChange };
};

interface ILoginForm {
    submitFailed: boolean;
}

const LoginForm: React.FC<ILoginForm> = ({ submitFailed }) => {
    const { t } = useTranslation(PAGE_NAMESPACE);
    const dispatch = useDispatch();
    const history = useHistory<ILocationState>();

    const email = getEmailValue(history);
    const initialValues = React.useRef({
        email,
    });

    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showNotifcation, setShowNotification] = useState(!!email);

    const { showLanguageSelector, languageOptions, currentLanguage, handleLanguageChange } = useHandleLanguages();

    useEffect(() => {
        if (submitFailed) {
            setIsLoading(false);
        }
    }, [submitFailed]);

    useEffect(() => {
        if (isAndroidBrowser()) {
            setIsChecked(true);
        }
    }, []);

    const loginAction = (formData: { email: string; password: string }): void => {
        if (showNotifcation) {
            setShowNotification(false);
        }
        setIsLoading(true);

        // Clear any errors from previous login attempts
        dispatch(sessionActions.clearSession());

        history.push({
            pathname: history.location.pathname,
            search: history.location.search,
            state: { hasSubmittedLoginForm: true },
        });

        const anonymousId = getAnonymousId();

        dispatch(sessionActions.login({ ...formData, anonymous_id: anonymousId }, { session_id: anonymousId }));
    };

    // N.B. We need to clear browser history state to prevent populating input on page refresh
    useEffect(() => {
        if (initialValues.current.email) clearBrowserHistory(history);
    }, [history, initialValues.current.email]);

    return (
        <Form
            name={LoginForm}
            initialValues={initialValues.current}
            onSubmit={loginAction}
            render={({ handleSubmit, pristine, invalid }): React.ReactElement => (
                // do not remove "data-monitoring" - is used for monitoring
                <form className={style.formWrapper} onSubmit={handleSubmit} data-monitoring="login-form">
                    <NotificationController
                        dataId="reset-password-notification"
                        isOpen={showNotifcation}
                        isDismissible
                        onDismiss={(): void => {
                            setShowNotification(false);
                        }}
                        displayState={Notification.DisplayState.WARNING}
                    >
                        <Trans
                            i18nKey="accountExistsError"
                            ns={PAGE_NAMESPACE}
                            components={[<Text.Link href="/reset">link-text-replaced-by-i18</Text.Link>]}
                        />
                    </NotificationController>
                    <Text.H1>{t('loginForm.mainTitle')}</Text.H1>
                    <FormLayout>
                        <FormLayout.Fieldset>
                            <FormLayout.Item>
                                <Label htmlFor="email">{t('loginForm.email.label')}</Label>

                                <Field
                                    name="email"
                                    type="email"
                                    parse={normalizeEmail}
                                    validate={(data): boolean =>
                                        isRequiredValidation()(data) || isEmailValidation()(data)
                                    }
                                >
                                    {({ input, meta }): React.ReactElement => (
                                        <TextInput
                                            id="email"
                                            name="email"
                                            type="email"
                                            tabIndex={1}
                                            isLightBg
                                            value={input.value}
                                            placeholder={t('loginForm.email.placeholder')}
                                            onBlur={input.onBlur}
                                            onChange={input.onChange}
                                            isValid={meta.touched && meta.valid}
                                            isInvalid={meta.touched && meta.invalid}
                                            errorMessage={(meta.touched && meta.error) || ''}
                                        />
                                    )}
                                </Field>
                            </FormLayout.Item>

                            <FormLayout.Item>
                                <div className={style.passwordLabelWrapper}>
                                    <Label htmlFor="password">{t('loginForm.password.label')}</Label>

                                    <Text.Link1 dataId="reset-password-anchor-link" href="/reset">
                                        {t('loginForm.forgotPassword')}
                                    </Text.Link1>
                                </div>

                                <Field
                                    name="password"
                                    type="password"
                                    validate={(data): boolean => isRequiredValidation()(data)}
                                >
                                    {({ input, meta }): React.ReactElement => (
                                        <Password
                                            id="password"
                                            name="password"
                                            tabIndex="2"
                                            isLightBg
                                            placeholder={t('loginForm.password.placeholder')}
                                            onBlur={input.onBlur}
                                            onChange={input.onChange}
                                            isValid={meta.touched && meta.valid}
                                            isInvalid={meta.touched && meta.invalid}
                                            errorMessage={(meta.touched && meta.error) || ''}
                                            value={input.value}
                                        />
                                    )}
                                </Field>
                            </FormLayout.Item>

                            {showLanguageSelector ? (
                                <FormLayout.Item>
                                    <Label htmlFor="language">{t('loginForm.selectLanguage')}</Label>
                                    <Field name="language" type="select">
                                        {({ input }): React.ReactElement => (
                                            <Dropdown
                                                options={languageOptions}
                                                {...input}
                                                name="language"
                                                tabIndex={3}
                                                value={currentLanguage}
                                                onChange={handleLanguageChange}
                                            />
                                        )}
                                    </Field>
                                </FormLayout.Item>
                            ) : null}

                            <FormLayout.Item>
                                <Field name="remember" type="checkbox">
                                    {({ input }): React.ReactElement => (
                                        <Checkbox
                                            {...input}
                                            tabIndex={4}
                                            onClick={(): void => setIsChecked(!isChecked)}
                                            checked={isChecked}
                                            theme={Checkbox.Theme.LIGHT}
                                            size={Checkbox.Size.REGULAR}
                                        >
                                            {t('loginForm.rememberMe')}
                                        </Checkbox>
                                    )}
                                </Field>
                            </FormLayout.Item>

                            <FormLayout.Item>
                                <Button
                                    htmlType="submit"
                                    dataId="submit"
                                    isResponsive
                                    type={Button.types.PRIMARY}
                                    size={Button.sizes.LARGE}
                                    isLoading={isLoading}
                                    isDisabled={pristine || isLoading || invalid}
                                    loadingLabel={t('loginForm.submit.loading')}
                                >
                                    {t('loginForm.submit.text')}
                                </Button>
                            </FormLayout.Item>
                        </FormLayout.Fieldset>
                    </FormLayout>

                    <div className={style.linksWrapper}>
                        <Text.Link1 dataId="cant-login-anchor-link" href="/login/help">
                            {t('loginForm.cantLogin')}
                        </Text.Link1>

                        <Text.Link1 dataId="register-email-anchor-link" href="/signup">
                            {t('loginForm.newAccount')}
                        </Text.Link1>
                    </div>
                </form>
            )}
        />
    );
};

export default LoginForm;
