import { types as marketTypes } from '@clearscore/redux.market';
import { types as sessionTypes } from '@clearscore/redux.session';
import { types as alertTypes } from '@clearscore/redux.alerts';

export default [
    marketTypes.MARKET_SET_ACTIVE_MARKET,
    marketTypes.MARKET_FETCH_CONFIG_SUCCESS,
    marketTypes.MARKET_SET_ACTIVE_LOCALE,
    sessionTypes.SET_SESSION,
    sessionTypes.LOGIN_OAUTH_AUTHORISE_SUCCESS,
    alertTypes.ALERTS_SHOW_ALERT,
    alertTypes.ALERTS_HIDE_ALERT,
];
