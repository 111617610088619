import createAction from '@clearscore/helpers.create-action';
import createRequestPayload from '@clearscore/helpers.create-request-payload';

export const showSentCodeAlert = (TYPE) => (mobileNumber) => ({ type: TYPE, payload: mobileNumber });

export const showTwoFactorAppAlert = (TYPE) => () => ({ type: TYPE });

export const resendTwoFactorAuth =
    (TYPE) =>
    ({ twoFactorAuthState, mobileNumber }, meta) =>
        createAction(
            TYPE,
            createRequestPayload(
                'international',
                '/login-service/v3/resend-2fa',
                'post',
                {
                    '2fa_state': twoFactorAuthState,
                },
                { ...meta, mobileNumber },
            ),
        );

export const verifyTwoFactorAuth =
    (TYPE) =>
    ({ code, twoFactorAuthState, ...restData }, meta) =>
        createAction(
            TYPE,
            createRequestPayload(
                'international',
                '/login-service/v3/verify-2fa',
                'post',
                {
                    ...restData,
                    '2fa_state': twoFactorAuthState,
                    '2fa_response': code,
                },
                meta,
            ),
        );
