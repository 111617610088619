import connect from '@clearscore/redux.connect';
import { selectors as loginSelectors } from '@clearscore/redux.session';
import formStatus from '@clearscore/helpers.form-status';

import Component from './component';

interface IMapState {
    submitFailed: boolean;
}

// todo: create global type for state
const mapState = (state: Record<string, unknown>): IMapState => {
    const loginFormStatus = formStatus(loginSelectors.getPredicate(state).fetch);

    return {
        submitFailed: loginFormStatus.submitFailed,
    };
};

export default connect(mapState)(Component);
