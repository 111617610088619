import createReduxModule from '@clearscore/redux.create-requests';

import * as actionCreators from './actions';

const redux = createReduxModule({ moduleName: 'login', actionCreators });

export const { types } = redux;
export const { actions } = redux;
export const { reducers } = redux;
export const { selectors } = redux;
