import { EN_GB, EN_AU } from '@clearscore/config.i18n';

export default {
    [EN_AU]: {
        next: 'Verify',
    },
    [EN_GB]: {
        timeText: 'Resend in',
        timeDelimiter: 's',
        link: 'Resend code',
        next: 'Next',
        pleaseEnter: 'Please enter a number into',
        boxSingular: 'box',
        boxesPlural: 'boxes',
        and: 'and',
        lastAttempt: 'You have one attempt remaining.',
    },
};
