import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useInterval from '@clearscore/hooks.use-interval';
import Button from '@clearscore/rainbow.button';

const Resend = ({ generateOtp, generateOtpStatus, otpCoolDownSeconds, language }) => {
    const [remainingRegenerationTime, setRemainingRegenerationTime] = useState(otpCoolDownSeconds);

    useInterval(() => {
        if (remainingRegenerationTime > 0) {
            setRemainingRegenerationTime(remainingRegenerationTime - 1);
        }
    }, 1000);

    useEffect(() => {
        // N.B we want to reset the timer when API has not been called yet or when the request was successful
        if (generateOtpStatus.isInitial || generateOtpStatus.isComplete) {
            setRemainingRegenerationTime(otpCoolDownSeconds);
        }
    }, [generateOtpStatus, otpCoolDownSeconds]);

    return (
        <Button
            dataId="otp-resend-button"
            type={Button.types.SECONDARY}
            size={Button.sizes.LARGE}
            isLoading={generateOtpStatus.isPending}
            isDisabled={remainingRegenerationTime !== 0 || generateOtpStatus.isPending}
            onClick={generateOtp}
            isResponsive
        >
            {remainingRegenerationTime === 0 ? language.link : null}

            {remainingRegenerationTime > 0 ? (
                <React.Fragment>
                    {language.timeText} {remainingRegenerationTime}
                    {language.timeDelimiter}
                </React.Fragment>
            ) : null}
        </Button>
    );
};

Resend.propTypes = {
    generateOtp: PropTypes.func.isRequired,
    otpCoolDownSeconds: PropTypes.number.isRequired,
    language: PropTypes.shape({
        link: PropTypes.string.isRequired,
        timeText: PropTypes.string.isRequired,
        timeDelimiter: PropTypes.string.isRequired,
    }).isRequired,
    generateOtpStatus: PropTypes.shape({
        isInitial: PropTypes.bool,
        isComplete: PropTypes.bool,
        isPending: PropTypes.bool,
    }).isRequired,
};

export default Resend;
