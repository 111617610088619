import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeSessionCookie } from '@clearscore/helpers.cookies';
import { actions as sessionActions } from '@clearscore/redux.session';
import { getLogoutMessage, getLogoutTheme } from '@clearscore-group/ui.external-cs-auth.logout';
import { actions as alertActions, constants as alertConstants } from '@clearscore/redux.alerts';
import { getSessionToken } from '@clearscore/helpers.session';
import connect from '@clearscore/redux.connect';
import withVertical from '@clearscore-group/ui.external-cs-auth.vertical';
import Container from '@clearscore/rainbow.container';

import reducer from './lib/reducers';
import epics from './lib/epics';
import middleware from './lib/middleware';
import appBridgeTypes from './lib/appBridgeTypes';
import AppDownloadBanner from './components/app-download-banner';
import ScreenVerify from './components/screen-verify';
import LoginForm from './components/login-form/container';
import useTracking from './lib/hooks/tracking';

const Login = ({ history }) => {
    const dispatch = useDispatch();
    const userHasSession = !!getSessionToken();
    const { trackLogin } = useTracking();

    useEffect(() => {
        if (userHasSession) {
            dispatch(sessionActions.clearSession());
            removeSessionCookie();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (userHasSession) {
            trackLogin({ event: 'fe_login' });
        }
    }, [userHasSession]);

    useEffect(() => {
        // Read a logout message; one can optionally be added at the point we fire the logout helper
        const logoutMessage = getLogoutMessage({ clear: true });
        const logoutTheme = getLogoutTheme({ clear: true }) ?? alertConstants.SUCCESS;

        if (logoutMessage) {
            dispatch(
                alertActions.showAlert({
                    alertContent: logoutMessage,
                    alertType: logoutTheme,
                }),
            );
        }
    }, []);

    return (
        <Fragment>
            <AppDownloadBanner />
            <Container size={Container.Size.SMALL}>
                <Switch>
                    <Route path="*/verify" component={ScreenVerify} />
                    <Route path="*" render={() => <LoginForm history={history} />} />
                </Switch>
            </Container>
        </Fragment>
    );
};

Login.propTypes = {
    history: PropTypes.object.isRequired,
};

const ConnectedComponent = connect(null, null, { useRouter: true })(Login);

export default withVertical(ConnectedComponent, {
    name: 'login',
    reducer,
    epics,
    middleware,
    appBridgeTypes,
});
