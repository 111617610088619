import { reducers as session } from '@clearscore/redux.session';
import { reducers as prospect } from '@clearscore/redux.prospect';
import { reducers as alerts } from '@clearscore/redux.alerts';

import { reducers } from '../redux';

export default {
    session: session.domain,
    alerts: alerts.domain,
    prospect: prospect.domain,
    login: reducers.domain,
};
