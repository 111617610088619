import React from 'react';
import ContextualAppDownloadBanner from '@clearscore/shared.contextual-app-download-banner';

import useTracking from './lib/hooks/tracking';

const WebappAppDownloadBanner = ({ ...props }) => {
    const { trackBannerViewed, trackBannerClicked } = useTracking();

    return (
        <ContextualAppDownloadBanner onBannerClick={trackBannerClicked} onBannerView={trackBannerViewed} {...props} />
    );
};

export default WebappAppDownloadBanner;
