import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from '@clearscore/rainbow.text-input';
import isNumber from '@clearscore/validation.is-number';

import styles from './multiple-single-digits.module.css';

class MultipleSingleDigits extends Component {
    inputRefs = {};

    fields = [...Array(this.props.numberOfFields)].map((_a, index) => ({
        name: `field${index}`,
    }));

    // state required for keeping track of fields meta,
    // where the value is required in order to be valid
    state = this.fields.reduce(
        (fields, field) => ({
            ...fields,
            [field.name]: '',
        }),
        {},
    );

    componentDidMount() {
        this.inputRefs.field0.focus();
    }

    getInputValue = () => this.fields.map((field) => this.state[field.name] || '').join('');

    getInputValuesWithIndexes = () => this.fields.map((field) => this.state[field.name]);

    onKeyDown = (event, index) => {
        if (event.key === 'Backspace') {
            this.handleBackspace(index);
            return event;
        }

        return event;
    };

    handleBackspace = (fieldIndex) => {
        const currField = this.fields[fieldIndex];
        const prevField = this.fields[fieldIndex - 1];

        if (currField && prevField) {
            const currentHasValue = !!this.state[currField.name];
            this.setState(
                (prevState) => ({
                    [currField.name]: '',
                    [prevField.name]: currentHasValue ? prevState[prevField.name] : '',
                }),
                () => {
                    this.props.input.onChange({
                        individualInputs: this.getInputValuesWithIndexes(),
                        inputValue: this.getInputValue(),
                    });
                    const fieldToFocus = currentHasValue ? currField : prevField;
                    this.inputRefs[fieldToFocus.name].focus();
                },
            );
        }
    };

    onChange = (value, fieldIndex) => {
        const { input: { onChange } = {} } = this.props;

        const isCopyAndPaste = value.length > 2;

        if (isCopyAndPaste) return;

        const currentField = this.fields[fieldIndex];
        const nextField = this.fields[fieldIndex + 1];

        const newFieldValues = this.handleSingleEntry(value, currentField, this.state[currentField.name]);

        this.setState(newFieldValues, () => {
            onChange({
                individualInputs: this.getInputValuesWithIndexes(),
                inputValue: this.getInputValue(),
            });

            // check if current field has value, in case user has backspaced on first input
            if (!isCopyAndPaste && !!this.state[currentField.name] && nextField) {
                this.inputRefs[nextField.name].focus();
            }
        });
    };

    handleCopyAndPaste = (event, inputFieldIndex) => {
        const eventValue = event.clipboardData.getData('text');
        const values = eventValue.split('');
        const newFieldValues = values.reduce((accumulatedValue, fieldValue, index) => {
            const currField = this.fields[inputFieldIndex + index];
            const nextField = this.fields[inputFieldIndex + index + 1];

            if (!currField) {
                return accumulatedValue;
            }

            if (nextField) {
                this.inputRefs[nextField.name].focus();
            }

            return { ...accumulatedValue, [currField.name]: fieldValue };
        }, {});

        this.setState(newFieldValues, () => {
            this.props.input.onChange({
                individualInputs: this.getInputValuesWithIndexes(),
                inputValue: this.getInputValue(),
            });
        });
    };

    handleSingleEntry = (value, currentField, currentInputValue) => {
        // last input field should contain only the last typed value
        const fieldValue = value.replace(currentInputValue, '');
        return {
            [currentField.name]: fieldValue,
        };
    };

    render() {
        const { placeholder, isDisabled, isDirtyAfterError } = this.props;

        return (
            <div className={styles.multipleSingleDigits}>
                {this.fields.map((field, fieldIndex) => {
                    const fieldValue = this.state[this.fields[fieldIndex].name];
                    const dirty = !!fieldValue;

                    return (
                        <TextInput
                            name={field.name}
                            type="text"
                            pattern="[0-9]*"
                            inputMode="numeric"
                            data-id="multiple-digits-text-input"
                            autoComplete="force-off"
                            key={field.name}
                            placeholder={placeholder}
                            isDisabled={isDisabled}
                            value={this.state[field.name]}
                            isValid={isNumber(fieldValue) === true && isDirtyAfterError}
                            isInvalid={isDirtyAfterError === false || (dirty && isNumber(fieldValue) === false)}
                            onPaste={(event) => this.handleCopyAndPaste(event, fieldIndex)}
                            onChange={(event) => this.onChange(event.target.value, fieldIndex)}
                            onKeyDown={(event) => this.onKeyDown(event, fieldIndex)}
                            elementRef={(inputRef) => {
                                this.inputRefs[field.name] = inputRef;
                            }}
                        />
                    );
                })}
            </div>
        );
    }
}

MultipleSingleDigits.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func.isRequired,
    }).isRequired,
    isDirtyAfterError: PropTypes.bool.isRequired,
    numberOfFields: PropTypes.number.isRequired,
    placeholder: PropTypes.string,
    isDisabled: PropTypes.bool,
};

MultipleSingleDigits.defaultProps = {
    placeholder: null,
    isDisabled: false,
};

export default MultipleSingleDigits;
